<template>
  <div class="checkAcc">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>结算管理</el-breadcrumb-item>
      <el-breadcrumb-item>结算账户</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="checkAcc-content">
      <el-form
        :model="checkAccForm"
        ref="numberValidateForm"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item label="收款人户名" prop="accName">
          <el-input
            style="width: 400px"
            size="small"
            v-model="checkAccForm.accName"
            autocomplete="off"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="收款人账号" prop="accNum">
          <el-input
            style="width: 400px"
            size="small"
            v-model="checkAccForm.accNum"
            autocomplete="off"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="收款人开户行" prop="bank">
          <el-input
            style="width: 400px"
            size="small"
            v-model="checkAccForm.bank"
            autocomplete="off"
            readonly
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getShopSettleBankInfo_api } from "@/api/shop.js"
export default {
  data() {
    return {
      checkAccForm: {
        accName: "",
        accNum: "",
        bank: ""
      }
    }
  },
  methods: {
    //获取结算账户
    async getShopSettleBankInfo() {
      let res = await getShopSettleBankInfo_api()
      this.checkAccForm.accName=res?.data?.data?.bank_name
      this.checkAccForm.accNum=res?.data?.data?.bank_number
      this.checkAccForm.bank=res?.data?.data?.bank_cnaps
    }
  },
  created(){
    this.getShopSettleBankInfo()
  }
}
</script>

<style lang="less" scoped>
.checkAcc {
  width: 100%;
  height: 100%;
  background: #eee;
  box-sizing: border-box;
  padding: 30px;
}
.checkAcc-content {
  height: 300px;
  background: #ffffff;
  border-radius: 5px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}
</style>